import React from 'react';
import { SEO } from 'components/SEO';
import { MainBlock } from './MainBlock';
import './styles.scss';

const Contacts = () => (
  <main className="page contacts-page">
    <SEO
      title="Контакты"
      description="Адрес и контактные данные онлайн-школы программирования, математики и шахмат для детей UP! School. Свяжитесь с нами любым для вас удобным способом."
    />
    <MainBlock />
  </main>
);

export default Contacts;
