import React from 'react';
import { BackgroundImg } from './BackgroundImg';
import houseImg from './images/house.svg';
import pinImg from './images/pin.svg';
import mailImg from './images/mail.svg';
import phoneImg from './images/phone.svg';
import { SOCIAL_LINKS } from 'constants/contacts';
import facebookImg from 'assets/images/socials/facebook.svg';
import instagramImg from 'assets/images/socials/instagram.svg';
import youtubeImg from 'assets/images/socials/youtube.svg';
// import youtubeImg from 'assets/images/contacts/youtube.png';

export const MainBlock = () => (
  <section className="main-block container">
    <div className="background-top" />
    <div className="main-proposition">
      <h1 className="title">Контакты</h1>
      <div className="contact-list">
        <div className="contact-item">
          <img src={houseImg} alt="Дом" className="contact-img" />
          <div className="contact-details">
            <h4 className="contact-title">Свидетельство о регистрации</h4>
            <div className="contact-text">
              № 191201676, выдано 17.11.2009г. Минским горисполкомом УНП
              191201676
            </div>
          </div>
        </div>
        <div className="contact-item">
          <img src={pinImg} alt="Дом" className="contact-img" />
          <div className="contact-details">
            <h4 className="contact-title">Юридический адрес</h4>
            <div className="contact-text">
              г. Минск, пер. Щербакова, д. 6, кв. 5
            </div>
          </div>
        </div>
        <div className="contact-item">
          <img src={mailImg} alt="Дом" className="contact-img" />
          <div className="contact-details">
            <h4 className="contact-title">Электронная почта</h4>
            <div className="contact-text">info@up-school.online</div>
          </div>
        </div>
        <div className="contact-item">
          <img src={phoneImg} alt="Дом" className="contact-img" />
          <div className="contact-details">
            <h4 className="contact-title">Телефон/факс</h4>
            <div className="contact-text">
              (017) 237-82-40
              <br />
              +375 (33) 657-55-33
              <br />
              +7 (916) 131-93-30
            </div>
          </div>
        </div>
      </div>
      <div className="social-links">
        <div className="other-links">
          <a
            href={SOCIAL_LINKS.facebook}
            className="social-link"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={facebookImg} alt="Facebook логотип" />
            <span>Facebook</span>
          </a>
          <a
            href={SOCIAL_LINKS.instagram}
            className="social-link"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={instagramImg} alt="Instagram логотип" />
            <span>Instagram</span>
          </a>
        </div>
        <div className="youtube-links">
          <a
            href={SOCIAL_LINKS.chessYoutube}
            className="youtube-link"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={youtubeImg} alt="Youtube логотип" />
            <span>YouTube по шахматам</span>
          </a>
          <a
            href={SOCIAL_LINKS.programmingYoutube}
            className="youtube-link"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={youtubeImg} alt="Youtube логотип" />
            <span>YouTube по программированию</span>
          </a>
        </div>
      </div>
    </div>
    <BackgroundImg />
  </section>
);
